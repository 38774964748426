import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "custom/css/bootstrap.min.css";
import './index.css';
import { Checkbox, Table, Glyphicon, Form, Label, HelpBlock, ControlLabel, FormControl, Panel,  Navbar, NavItem, NavDropdown, MenuItem, Nav, Grid, Row, Col, Button } from "react-bootstrap";
import * as serviceWorker from './serviceWorker';

// const url = 'http://site-cheker.max';
const url = '';

/**************************** APP ****************************/
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      currentAction: null,
      userId: '',
      userName: '',
      userRole: '',
      enterLogin: '',
      enterPass: '',
      showPass: false,
      message: {
        status: '',
        text: '',
      },
    };
    this.handleSelectAction = this.handleSelectAction.bind(this);
    this.handleChangeEnterLogin = this.handleChangeEnterLogin.bind(this);
    this.handleChangeEnterPass = this.handleChangeEnterPass.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeShowPass = this.handleChangeShowPass.bind(this);
  }

  handleSelectAction(selectedKey) {
    this.setState({
      currentAction: selectedKey,
    });
  }

  handleChangeEnterLogin(e) {
    this.setState({
      enterLogin: e.target.value,
      message: {
        status: '',
        text: '',
      },
    });
  }

  handleChangeEnterPass(e) {
    this.setState({
      enterPass: e.target.value,
      message: {
        status: '',
        text: '',
      },
    });
  }

  handleLogin() {
    this.setState({
      message: {
        status: '',
        text: '',
      },
    });
    if(this.state.enterLogin.length < 3 || this.state.enterPass.length < 7){
      this.setState({
        message: {
          status: 'error',
          text: 'Заполните все поля (Логин, Пароль)',
        },
      });
    } else {

      var postData = new FormData();
      postData.append( "enterLogin", this.state.enterLogin );
      postData.append( "enterPass", this.state.enterPass );

      fetch(url + '/ajax/loginUser.php', {
        method: 'post',
        body: postData,
      }).then(res => res.json())
      .then(data => {
        if (data.status === 'error') {
          this.setState({
            message: {
              status: 'error',
              text: 'Ошибка при входе в систему',
            },
          });
        } else if(data.status === 'notLogin') {
          this.setState({
            message: {
              status: 'error',
              text: 'Не правильный логин или пароль',
            },
          });
        } else if(data.status === 'login') {
          this.setState({
            userId: data.userData.userId,
            userName: data.userData.userName,
            userRole: data.userData.userRole,
            enterLogin: '',
            enterPass: '',
            message: {
              status: 'success',
              text: 'Вы успешно вошли в систему',
            },
          });
        } else {
          this.setState({
            message: {
              status: 'error',
              text: 'Неизвесная Ошибка',
            },
          });
        }
      }).catch(error => console.error(error))
    }

  }

  handleGoHome(){
    this.setState({
      currentAction: null,
      message: {
        status: '',
        text: '',
      },
    });
  }

  handleLogOut(){
    this.setState({
      currentAction: null,
      userId: '',
      userName: '',
      userRole: '',
      enterLogin: '',
      enterPass: '',
      showPass: false,
      message: {
        status: '',
        text: '',
      },
    });
  }

  handleSubmit(e){
    e.preventDefault();
  }

  handleChangeShowPass(e){
    this.setState({
      showPass: e.target.checked,
    });
  }

  render() {
    if(this.state.userName === '' || this.state.userId === '' || this.state.userRole === '' ){
      return (
        <div>
          <Header userName='Гость' onGoHome={this.handleGoHome} />
          <Grid className='mainWrap'>
            <Row className="show-grid">
              <Col md={4} mdPush={4}>
                <div className="stage">
                  <img src="/img/logo.png" alt="logo" className="center-block img-responsive rot"/>
                </div>
                <br />
                <Panel bsStyle="primary">
                  <Panel.Heading>
                    <Panel.Title componentClass="h3"><Glyphicon glyph="user" /> Авторизация Пользователя</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Form onSubmit={this.handleSubmit}>
                      <ControlLabel>Логин</ControlLabel>
                      <FormControl type="text" value={this.state.enterLogin} placeholder="Введите Логин" onChange={this.handleChangeEnterLogin} />
                      <br />
                      <ControlLabel>Пароль</ControlLabel>
                      <FormControl type={this.state.showPass ? 'text' : 'password'} value={this.state.enterPass} placeholder="Введите Пароль" onChange={this.handleChangeEnterPass} />
                      <Checkbox checked={this.state.showPass} onChange={this.handleChangeShowPass}> Показать Пароль</Checkbox>

                      {this.state.message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {this.state.message.text}</p> : ''}
                      {this.state.message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {this.state.message.text}</p> : ''}

                      <Button bsStyle="warning" bsSize="small" type="submit" onClick={this.handleLogin}>Войти</Button>
                    </Form>
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Grid>
          <Fotter />
        </div>
      );
    } else {
      const currentAction = this.state.currentAction;
      return (
        <div>
          <Header userName={this.state.userName} onLogOut={this.handleLogOut} onGoHome={this.handleGoHome}/>
          <Grid className='mainWrap'>
            <Row className="show-grid">
              <Col md={3}>
                <Panel bsStyle="primary">
                  <Panel.Heading>
                    <Panel.Title componentClass="h3"><Glyphicon glyph="tasks" /> Отчеты</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Nav bsStyle="pills" stacked onSelect={this.handleSelectAction}>
                      <NavItem eventKey='viewReportAll'><Glyphicon glyph="align-justify" /> Общие отчеты</NavItem>
                      <NavItem eventKey='viewReport'><Glyphicon glyph="list" /> Отчеты по сайтам</NavItem>
                      <NavItem eventKey='codeForCrone'><Glyphicon glyph="link" /> Код для крона</NavItem>
                    </Nav>
                  </Panel.Body>
                </Panel>

                <Panel bsStyle="primary">
                  <Panel.Heading>
                    <Panel.Title componentClass="h3"><Glyphicon glyph="globe" /> Сайты</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <Nav bsStyle="pills" stacked onSelect={this.handleSelectAction}>
                      <NavItem eventKey='addSite'><Glyphicon glyph="ok" /> Добавить Сайт</NavItem>
                      <NavItem eventKey='editSite'><Glyphicon glyph="pencil" /> Редактировать Сайт</NavItem>
                      <NavItem eventKey='removeSite'><Glyphicon glyph="trash" /> Удалить Сайт</NavItem>
                    </Nav>
                  </Panel.Body>
                </Panel>

                {this.state.userRole === '1' ?
                  <Panel bsStyle="primary">
                    <Panel.Heading>
                      <Panel.Title componentClass="h3"><Glyphicon glyph="user" /> Акаунты</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      <Nav bsStyle="pills" stacked onSelect={this.handleSelectAction}>
                        <NavItem eventKey='addUser'><Glyphicon glyph="ok" /> Создать Акаунт</NavItem>
                        <NavItem eventKey='editUser'><Glyphicon glyph="pencil" /> Редактировать Акаунт</NavItem>
                        <NavItem eventKey='removeUser'><Glyphicon glyph="trash" /> Удалить Акаунт</NavItem>
                      </Nav>
                    </Panel.Body>
                  </Panel> : ''}
              </Col>
              <Col md={9}>
                {(currentAction === null)    ? <DefaultFrame userName={this.state.userName} /> : ''}
                {(currentAction === 'viewReportAll') ? <ViewReportAll userId={this.state.userId} userRole={this.state.userRole} /> : ''}
                {(currentAction === 'viewReport')    ? <ViewReport userId={this.state.userId} userRole={this.state.userRole} /> : ''}
                {(currentAction === 'codeForCrone')  ? <CronJobs /> : ''}

                {(currentAction === 'addSite')    ? <AddSite userId={this.state.userId} /> : ''}
                {(currentAction === 'editSite')   ? <EditSite userId={this.state.userId} userRole={this.state.userRole} /> : ''}
                {(currentAction === 'removeSite') ? <RemoveSite userId={this.state.userId} userRole={this.state.userRole} /> : ''}

                {(currentAction === 'addUser')    ? <AddUser />    : ''}
                {(currentAction === 'editUser')   ? <EditUser />   : ''}
                {(currentAction === 'removeUser') ? <RemoveUser /> : ''}
              </Col>
            </Row>
          </Grid>
          <Fotter />
        </div>
      );
    }
  }
}

/**************************** CronJobs ****************************/
class CronJobs extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      currentDirectory: '',
      message: {
        status: null,
        text: null,
      },
    };
    this.handleClickCheckScanSites = this.handleClickCheckScanSites.bind(this);
    this.handleClickChecSendMails = this.handleClickChecSendMails.bind(this);
  }

  handleClickCheckScanSites() {
    this.setState({
      message: {
        status: null,
        text: null,
      },
    });
    fetch(url + '/ajax/checkSites.php', {
      method: 'get',
    }).then(data => {
      this.setState({
        message: {
          status: 'success',
          text: 'Проверка сайтов прошла успешно. Проверьте результаты в разделе "Отчеты по сайтам".',
        },
      });
    }).catch(error => {
      this.setState({
        message: {
          status: 'error',
          text: 'При проверке сайтов произошла ошибка.',
        },
      });
    });
  }

  handleClickChecSendMails() {
    this.setState({
      message: {
        status: null,
        text: null,
      },
    });
    fetch(url + '/ajax/sendReports.php', {
      method: 'get',
    }).then(data => {
      this.setState({
        message: {
          status: 'success',
          text: 'Проверка отправки уведомлений на емейл прошла успешно. Проверьте почту.',
        },
      });
    }).catch(error => {
      this.setState({
        message: {
          status: 'error',
          text: 'При проверке отправки уведомлений произошла ошибка.',
        },
      });
    });
  }

  componentDidMount(){
    fetch(url + '/ajax/getCurrentDirectory.php', {
      method: 'get',
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0) {
        this.setState({
          currentDirectory: data,
        });
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'Ошибка при получении текущей директории',
          },
        });
      }
    }).catch(error => console.error(error))
  }

  render(){
    const message = this.state.message;
    const currentDirectory = this.state.currentDirectory;
    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="trash" /> Код Для Крона</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <p><b>Для проверки сайтов: (Command)</b></p>
                <p>/usr/local/bin/php  {currentDirectory}/checkSites.php >/dev/null 2>&1</p>
                <br />
                <p><b>Для отправки отчетов: (Command)</b></p>
                <p>/usr/local/bin/php  {currentDirectory}/sendReports.php >/dev/null 2>&1</p>
                <br />
                <p><i><small>* Задачи для Cron настраиваются в Cpanel в разделе Cron Jobs. При создании задания выбираем частоту сканирования и заполняем поле Command выше описаной информацией. По сути надо создать два задания, первое - которое будет отвечать за сканирование сайтов, а второе за отправку уведомленийна почту. <br />* Для уведомления информация берется за последние 24 часа от времени отправки письма. Если за последние 24 часа не было сайтов оффлайн - то письма не будут отправлятся. <br />* Уведомления на почту про оффлайн - получают только владельцы сайта.</small></i></p>
                <br />
                <p><b>Принудительная проверка сайтов</b></p>
                <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleClickCheckScanSites}>Запустить проверку всех сайтов</Button>
                <br /><br />
                <p><b>Принудительная отправка уведомлений на почту:</b></p>
                <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleClickChecSendMails}>Запустить отправку уведомлений</Button>
                <p><i><small>* Если за последние 24 часа не было найдено сайтов оффлайн - емейл уведомления не будут отправлены.</small></i></p>
                <br />
                {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}

              </Panel.Body>
            </Panel>);
  }
}

/**************************** DefaultFrame ****************************/
class DefaultFrame extends Component {
  render(){
    let userName = this.props.userName.slice(0, 1).toUpperCase() + this.props.userName.slice(1);
    return <div>
            <br />
            <br />
            <br />
            <div className="stage">
              <img src="/img/logo.png" alt="logo" className="center-block img-responsive rot"/>
            </div>
            <h1 className="text-center">Привет, {userName}</h1>
            <p className="text-center">Site Checker будет следить за вашими  сайтами и уведомит вас если сайт уйдет в оффлайн.</p>
            <p className="text-center">Теперь Вы можете выбрать нужное вам действие слева.</p>
           </div>
  }
}

/**************************** ViewReport ****************************/
class ViewReportAll extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeHideSuccess = this.handleChangeHideSuccess.bind(this);
    this.state = {
      message: {
        status: null,
        text: null,
      },
      listSites: null,
      listReports: null,
      hideSuccess: true,
      currentPeriod: 1,
      listPeriods: [
        ['1', 'За сегодня'],
        ['2', 'За 2 дня'],
        ['3', 'За 3 дня'],
        ['5', 'За 5 дней'],
        ['7', 'За 7 дней'],
        ['10', 'За 10 дней'],
        ['14', 'За 14 дней'],
        ['30', 'За 30 дней'],
        ['60', 'За 60 дней'],
        ['90', 'За 90 дней'],
        ['180', 'За 180 дней'],
        ['365', 'За год'],
      ]
    };
  }

  componentDidMount(){
    var postData = new FormData();
    postData.append( "userId", this.props.userId );
    postData.append( "userRole", this.props.userRole );
    postData.append( "addStatisticInfo", false );

    fetch(url + '/ajax/getListSites.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0){
        let newListSites = [];
        data.forEach(function (row){
          newListSites[row['id']] = row;
        });

        this.setState({
          listSites: newListSites,
        });

        var postData = new FormData();
        postData.append( "userId", this.props.userId );
        postData.append( "userRole", this.props.userRole );
        postData.append( "currentPeriod", this.state.currentPeriod );

        fetch(url + '/ajax/getListReportsByPeriod.php', {
          method: 'post',
          body: postData,
        }).then(res => res.json())
        .then(data => {
          if(data.length > 0){
            this.setState({
              listReports: data,
            });
          } else {
            this.setState({
              listReports: null,
              message: {
                status: 'error',
                text: 'По данному периоду пока нет отчетов',
              },
            });
          }
        }).catch(error => console.error(error));
      }
    }).catch(error => console.error(error))
  }

  handleChangeHideSuccess(event){
    this.setState({
      hideSuccess: this.state.hideSuccess ? false : true,
    });
  }

  handleChangeSelect(event){
    this.setState({
      currentPeriod: event.target.value,
      listReports: null,
      message: {
        status: null,
        text: null,
      },
    });

    var postData = new FormData();
    postData.append( "userId", this.props.userId );
    postData.append( "userRole", this.props.userRole );
    postData.append( "currentPeriod", event.target.value );

    fetch(url + '/ajax/getListReportsByPeriod.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {

      if(data.length > 0){
        this.setState({
          listReports: data,
        });
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'По данному периоду пока нет отчетов',
          },
        });
      }
    }).catch(error => console.error(error))
  }

  render(){
    const message = this.state.message;
    const currentPeriod = this.state.currentPeriod;
    const listSites = this.state.listSites;
    const listReports = this.state.listReports;
    const hideSuccess = this.state.hideSuccess;
    const listPeriods  = this.state.listPeriods;

    let listOption = "";
    if (listPeriods) {
      listOption = listPeriods.map((row) => <option key={row[0]} value={row[0]}>{row[1]}</option>);
    }

    let listReportsCode = '';
    let i = 1;
    if (listReports) {
      listReportsCode = listReports.map((row) => <tr key={row.id} className="reportTable" className={hideSuccess ? (row.status === '1' ? 'hidden': '') : ''}>
        <td>{row.date}</td>
        <td className={row.status === '1' ? 'errorReport' : 'successReport'}>{row.status === '1' ? 'Код Найден' : 'Код Не Найден'}</td>
        <td>{listSites[row.site_id]['site']}</td>
        <td>{row.errors}</td>
      </tr>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="link" /> Общие отчеты по периодам</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <ControlLabel>Выберите период:</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={currentPeriod} onChange={this.handleChangeSelect}>
                  {listOption}
                </FormControl>

                <Checkbox checked={hideSuccess} onChange={this.handleChangeHideSuccess}>
                  Показать только сайты с ненайденым кодом отслеживания
                </Checkbox>

                <br />
                <h4><u>Отчет по текущему периоду</u> </h4>
                <hr />
                <Table striped bordered condensed hover responsive className="tableWithLinks">
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Статус</th>
                      <th>Сайт</th>
                      <th>Описание ошибки</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listReportsCode ? listReportsCode : <tr><td></td><td></td><td></td><td></td></tr>}
                  </tbody>
                </Table>
                <p><i><small>* В отчете за определенный период выводится до 1500 последних записей. <br />* Админ вможет просмотреть отчеты по всем сайтам, Сеошник - только свои сайты. <br />* Уведомления на почту про оффлайн - получают только владельцы сайта.</small></i></p>
                <br />
                {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
              </Panel.Body>
            </Panel>);
  }
}


/**************************** ViewReport ****************************/
class ViewReport extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeHideSuccess = this.handleChangeHideSuccess.bind(this);
    this.state = {
      message: {
        status: null,
        text: null,
      },
      listSites: null,
      currentSiteId: 0,
      listReports: null,
      hideSuccess: true,
    };
  }

  handleChangeHideSuccess(event){
    this.setState({
      hideSuccess: this.state.hideSuccess ? false : true,
    });
  }

  componentDidMount(){
    var postData = new FormData();
    postData.append( "userId", this.props.userId );
    postData.append( "userRole", this.props.userRole );
    postData.append( "addStatisticInfo", false );

    fetch(url + '/ajax/getListSites.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0){
        let newListSites = [];
        data.forEach(function (row){
          newListSites[row['id']] = row;
        });

        this.setState({
          listSites: newListSites,
          currentSiteId: data[0]['id'],
        });

        var postData = new FormData();
        postData.append( "id", data[0]['id'] );

        fetch(url + '/ajax/getListReportsBySiteId.php', {
          method: 'post',
          body: postData,
        }).then(res => res.json())
        .then(data => {
          if(data.length > 0){
            this.setState({
              listReports: data,
            });
          } else {
            this.setState({
              listReports: null,
              message: {
                status: 'error',
                text: 'По данному сайту пока нет отчетов',
              },
            });
          }
        }).catch(error => console.error(error));
      }
    }).catch(error => console.error(error))
  }

  handleChangeSelect(event){
    this.setState({
      currentSiteId: event.target.value,
      listReports: null,
      message: {
        status: null,
        text: null,
      },
    });

    var postData = new FormData();
    postData.append( "id", event.target.value );

    fetch(url + '/ajax/getListReportsBySiteId.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0){
        this.setState({
          listReports: data,
        });
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'По данному сайту пока нет отчетов',
          },
        });
      }
    }).catch(error => console.error(error))
  }

  render(){
    const message = this.state.message;
    const currentSiteId = this.state.currentSiteId;
    const listSites = this.state.listSites;
    const listReports = this.state.listReports;
    const hideSuccess = this.state.hideSuccess;

    let listOption = "";
    if (listSites) {
      listOption = listSites.map((row) => <option key={row.id} value={row.id}>{row.site}</option>);
    }

    let listReportsCode = '';
    let i = 1;
    if (listReports) {
      listReportsCode = listReports.map((row) => <tr key={row.id} className="reportTable" className={hideSuccess ? (row.status === '1' ? 'hidden': '') : ''}>
        <td>{row.date}</td>
        <td className={row.status === '1' ? 'errorReport' : 'successReport'}>{row.status === '1' ? 'Код Найден' : 'Код Не Найден'}</td>
        <td>{row.errors}</td>
      </tr>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="link" /> Отчеты по сайтам</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <ControlLabel>Выберите сайт:</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={currentSiteId} onChange={this.handleChangeSelect}>
                  {listOption}
                </FormControl>
                <Checkbox checked={hideSuccess} onChange={this.handleChangeHideSuccess}>
                  Показать только сайты с ненайденым кодом отслеживания
                </Checkbox>
                <br />

                <h4><u>Отчет по текущему сайту</u> </h4>
                <hr />
                <Table striped bordered condensed hover responsive className="tableWithLinks">
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Статус</th>
                      <th>Описание ошибки</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listReportsCode ? listReportsCode : <tr><td></td><td></td><td></td></tr>}
                  </tbody>
                </Table>
                <p><i><small>* В отчете по сайту выводится до 500 последних записей. <br />* Админ вможет просмотреть отчеты по всем сайтам, Сеошник - только свои сайты. <br />* Уведомления на почту про оффлайн - получают только владельцы сайта.</small></i></p>
                <br />
                {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
              </Panel.Body>
            </Panel>);
  }
}

/**************************** REMOVE SITE ****************************/
class RemoveSite extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      listSites: null,
      siteId: 0,
      message: {
        status: null,
        text: null,
      },
    };
  }

  componentDidMount(){
    var postData = new FormData();
    postData.append( "userId", this.props.userId );
    postData.append( "userRole", this.props.userRole );
    postData.append( "addStatisticInfo", 'false' );

    fetch(url + '/ajax/getListSites.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0) {
        let newListSites = [];
        data.forEach(function (row){
          newListSites[row['id']] = row;
        });
        this.setState({
          listSites: newListSites,
          siteId: data[0]['id'],
        });
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'У вас еще нет сайтов',
          },
        });
      }
    }).catch(error => console.error(error))
  }

  handleChangeSelect(event){
    this.setState({
      siteId: event.target.value,
    });
  }

  handleRemove() {
    if(this.state.listSites !== null) {
      if(window.confirm('Вы уверены что хотите удалить сайт: ' + this.state.listSites[this.state.siteId]['site'] + '?')){
        fetch(url + '/ajax/removeSite.php?url=' + this.state.listSites[this.state.siteId]['site'] + '&id=' + this.state.siteId)
        .then(res => res.json())
        .then(data => {
          if(data.status === 'success'){
            var postData = new FormData();
            postData.append( "userId", this.props.userId );
            postData.append( "userRole", this.props.userRole );
            postData.append( "addStatisticInfo", 'false' );

            fetch(url + '/ajax/getListSites.php', {
              method: 'post',
              body: postData,
            }).then(res => res.json())
            .then(data => {
              if(data.length > 0) {
                let newListSites = [];
                data.forEach(function (row){
                  newListSites[row['id']] = row;
                });
                this.setState({
                  listSites: newListSites,
                  siteId: data[0]['id'],
                });
              } else {
                this.setState({
                  listSites: null,
                  siteId: 0,
                  message: {
                    status: 'error',
                    text: 'У вас еще нет сайтов',
                  },
                });
              }
            }).catch(error => console.error(error))

            this.setState({
              message: {
                status: 'success',
                text: 'Сайт: '+data.site+' - Успешно удален!',
              },
            });
          } else {
            this.setState({
              message: {
                status: 'error',
                text: 'При удалении сайта произошла ошибка',
              },
            });
          }
        }).catch(error => console.error(error))
      }
    }
  }

  render(){
    const message = this.state.message;
    const listSites = this.state.listSites;
    let listOption = "";

    if (listSites) {
      listOption = listSites.map((row) => <option key={row.id} value={row.id}>{row.site}</option>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="trash" /> Удалить Сайт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <ControlLabel>Выберите сайт:</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={this.state.siteId} onChange={this.handleChangeSelect}>
                  {listOption}
                </FormControl>
                <p><i><small>* Админ может удалить все сайты, Сеошник - только свои.</small></i></p>
                <br />
                {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                <Button bsStyle="primary" bsSize="small" onClick={this.handleRemove}>Удалить</Button>
              </Panel.Body>
            </Panel>);
  }
}

/**************************** EDIT SITE ****************************/
class EditSite extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleChangeInputUrl = this.handleChangeInputUrl.bind(this);
    this.handleChangeInputCode = this.handleChangeInputCode.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      listSites: null,
      siteId: 0,
      siteUrl: '',
      siteCode: '',
      message: {
        status: null,
        text: null,
      },
    };
  }

  componentDidMount(){
    var postData = new FormData();
    postData.append( "userId", this.props.userId );
    postData.append( "userRole", this.props.userRole );
    postData.append( "addStatisticInfo", 'false' );

    fetch(url + '/ajax/getListSites.php', {
      method: 'post',
      body: postData,
    }).then(res => res.json())
    .then(data => {
      if(data.length > 0) {
        let newListSites = [];
        data.forEach(function (row){
          newListSites[row['id']] = row;
        });

        this.setState({
          listSites: newListSites,
          siteId: data[0]['id'],
          siteUrl: data[0]['site'],
          siteCode: data[0]['code'],
        });
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'У вас еще нет сайтов',
          },
        });
      }
    }).catch(error => console.error(error))
  }

  handleSubmit(e){
    e.preventDefault();
  }

  handleChangeSelect(event){
    this.setState({
      siteId: event.target.value,
      siteUrl: this.state.listSites[event.target.value]['site'],
      siteCode: this.state.listSites[event.target.value]['code'],
    });
  }

  handleChangeInputUrl(e) {
    this.setState({ siteUrl: e.target.value });
  }

  handleChangeInputCode(e) {
    this.setState({ siteCode: e.target.value });
  }

  handleSave() {
    if(this.state.listSites !== null){
      if(window.confirm('Вы уверены что хотите изменить домен сайта на: ' + this.state.siteUrl + ' ?')){
        const lengthUrl = this.state.siteUrl.length;
        const lengthCode = this.state.siteCode.length;
        if(lengthUrl > 3 && lengthCode > 3){
          var postData = new FormData();
          postData.append( "url", this.state.siteUrl );
          postData.append( "code", this.state.siteCode );

          fetch(url + '/ajax/checkSite.php', {
            method: 'post',
            body: postData,
          }).then(res => res.json())
          .then(data => {

            if(data){
              this.setState({
                message: {
                  status: 'error',
                  text: data,
                },
                // siteUrl: '',
                // siteCode: '',
              });
            } else {

              fetch(url + '/ajax/editSite.php?url=' + this.state.siteUrl + '&id=' + this.state.siteId + '&code=' + this.state.siteCode)
                .then(res => res.json())
                .then(data => {

                  if(data.status === 'success'){

                    var postData = new FormData();
                    postData.append( "userId", this.props.userId );
                    postData.append( "userRole", this.props.userRole );
                    postData.append( "addStatisticInfo", 'false' );

                    fetch(url + '/ajax/getListSites.php', {
                      method: 'post',
                      body: postData,
                    }).then(res => res.json())
                    .then(data => {
                      let newListSites = [];
                      data.forEach(function (row){
                        newListSites[row['id']] = row;
                      });

                      this.setState({
                        listSites: newListSites,
                      });
                      // console.log(data);
                    }).catch(error => console.error(error))

                    this.setState({
                      message: {
                        status: 'success',
                        text: 'Сайт: '+data.site+' - Успешно обновлен',
                      },
                      siteUrl: data.site,
                      siteCode: data.code,
                    });
                  } else {
                    this.setState({
                      message: {
                        status: 'error',
                        text: 'При добавлении сайта в базу произошла ошибка',
                      },
                    });
                  }

                }).catch(error => console.error(error));


            }
          }).catch(error => {
            console.error(error + "123");
            this.setState({
              message: {
                status: 'error',
                text: 'При проверке сайта произошла ошибка. Проверьте правильно ли ввели домен. (Если правильно - то возможно сайт блокирует соединения сервер-сервер).',
              },
            });
          });






        } else {
          this.setState({
            message: {
              status: 'error',
              text: 'Вы не ввели адрес сайта или код отслеживания',
            }
          });
        }
      }
    }
  }

  render(){
    const message = this.state.message;
    const listSites = this.state.listSites;
    let listOption = "";

    if (listSites) {
      listOption = listSites.map((row) => <option key={row.id} value={row.id}>{row.site}</option>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="pencil" /> Редактировать Сайт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Form onSubmit={this.handleSubmit}>
                  <ControlLabel>Выберите сайт:</ControlLabel>
                  <FormControl componentClass="select" placeholder="select" value={this.state.siteId} onChange={this.handleChangeSelect}>
                    {listOption}
                  </FormControl>
                  <br />
                  <ControlLabel>Отредактируйте домен:</ControlLabel>
                  <FormControl type="text" value={this.state.siteUrl} placeholder="Введите домен сайта" onChange={this.handleChangeInputUrl} />
                  <HelpBlock>Пример: http://site.com или http://www.site.com. Минимум 4 символа.</HelpBlock>

                  <ControlLabel>Отредактируйте код отслеживания:</ControlLabel>
                  <FormControl type="text" value={this.state.siteCode} placeholder="Введите код отслеживания" onChange={this.handleChangeInputCode} />
                  <HelpBlock>Пример: body.abc555  - site checker будет искать тег body с классом abc555. Минимум 4 символа.</HelpBlock>
                  <p><i><small>* Админ может редактировать все сайты, Сеошник - только свои.</small></i></p>
                  <br />
                  {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                  {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                  <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleSave}>Сохранить</Button>
                </Form>
              </Panel.Body>
            </Panel>);
  }
}

/**************************** ADD SITE ****************************/
class AddSite extends Component {
  constructor(props) {
    super(props);
    this.handleChangeUrl = this.handleChangeUrl.bind(this);
    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      siteUrl: '',
      siteCode: '',
      message: {
        status: null,
        text: null,
      },
    };
  }

  handleSubmit(e){
    e.preventDefault();
  }

  handleChangeUrl(e) {
    this.setState({ siteUrl: e.target.value });
  }

  handleChangeCode(e) {
    this.setState({ siteCode: e.target.value });
  }

  handleClick(message) {
    this.setState({
      message: {
        status: '',
        text: '',
      },
    });
    const lengthUrl = this.state.siteUrl.length;
    const lengthCode = this.state.siteCode.length;
    if(lengthUrl > 3 && lengthCode > 3){
      var postData = new FormData();
      postData.append( "url", this.state.siteUrl );
      postData.append( "code", this.state.siteCode );

      fetch(url + '/ajax/checkSite.php', {
        method: 'post',
        body: postData,
      }).then(res => res.json())
      .then(data => {

        if(data){
          this.setState({
            message: {
              status: 'error',
              text: data,
            },
            // siteUrl: '',
            // siteCode: '',
          });
        } else {

          var postData = new FormData();
          postData.append( "userId", this.props.userId );
          postData.append( "url", this.state.siteUrl );
          postData.append( "code", this.state.siteCode );

          fetch(url + '/ajax/addSite.php', {
            method: 'post',
            body: postData,
          }).then(res => res.json())
          .then(data => {

            if(data.status === 'success'){
              this.setState({
                message: {
                  status: 'success',
                  text: 'Сайт: '+data.site+' - Успешно добавлен',
                },
                siteUrl: '',
                siteCode: '',
              });
            } else {
              this.setState({
                message: {
                  status: 'error',
                  text: 'При добавлении сайта в базу произошла ошибка',
                },
              });
            }

          }).catch(error => console.error(error))

        }
      }).catch(error => {
        console.error(error + "123");
        this.setState({
          message: {
            status: 'error',
            text: 'При проверке сайта произошла ошибка. Проверьте правильно ли ввели домен. (Если правильно - то возможно сайт блокирует соединения сервер-сервер).',
          },
        });
      });

    } else {
      this.setState({
        message: {
          status: 'error',
          text: 'Вы не ввели адрес сайта или код для отслеживания',
        }
      });
    }
  }

  render(){
    const message = this.state.message;

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="ok" /> Добавить Сайт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Form onSubmit={this.handleSubmit}>
                  <ControlLabel>Сайт:</ControlLabel>
                  <FormControl type="text" value={this.state.siteUrl} placeholder="Введите домен сайта" onChange={this.handleChangeUrl} />
                  <HelpBlock>Пример: http://site.com или http://www.site.com. Минимум 4 символа.</HelpBlock>
                  <ControlLabel>Код для отслеживания:</ControlLabel>
                  <FormControl type="text" value={this.state.siteCode} placeholder="Введите код для отслеживания" onChange={this.handleChangeCode} />
                  <HelpBlock>Пример: body.abc555  - site checker будет искать тег body с классом abc555. Минимум 4 символа.</HelpBlock>
                  {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                  {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                  <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleClick}>Добавить</Button>
                </Form>
              </Panel.Body>
            </Panel>);
  }
}

/**************************** HEADER ****************************/
class Header extends Component {
  constructor(props) {
    super(props);
    this.handleLogOutClick = this.handleLogOutClick.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
  }

  handleLogOutClick(){
    this.props.onLogOut();
  }

  handleGoHome(){
    this.props.onGoHome();
  }

  render(){
    let userName = this.props.userName.slice(0, 1).toUpperCase() + this.props.userName.slice(1);
    return (
      <Navbar inverse collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <span onClick={this.handleGoHome}><Glyphicon glyph="menu-left" />SITE <img alt="logo" src="/img/logo_menu.png"/> CHEKER<Glyphicon glyph="menu-right" /></span>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
            {this.props.userName !== 'Гость' ?
             <NavDropdown title={'Привет , ' + userName} id="user-nav-dropdown"><MenuItem onClick={this.handleLogOutClick}><Glyphicon glyph="off" /> Выйти</MenuItem></NavDropdown> :
             <NavItem>{'Привет , ' + userName + ''}</NavItem>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

/**************************** FOOTER ****************************/
class Fotter extends Component {
  render (){
    return <footer>Copyright &copy; 2018 Site Checker</footer>
  }
}

/**************************** ADD USER ****************************/
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangeUserMail = this.handleChangeUserMail.bind(this);
    this.handleChangeUserPass = this.handleChangeUserPass.bind(this);
    this.handleChangeUserPole = this.handleChangeUserPole.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      userName: '',
      userMail: '',
      userPass: '',
      userRole: 2,
      message: {
        status: null,
        text: null,
      },
    };
  }
  handleSubmit(e){
    e.preventDefault();
  }
  handleChangeUserName(e) {
    this.setState({ userName: e.target.value });
  }
  handleChangeUserMail(e) {
    this.setState({ userMail: e.target.value });
  }
  handleChangeUserPass(e) {
    this.setState({ userPass: e.target.value });
  }
  handleChangeUserPole(e) {
    this.setState({ userRole: e.target.value });
  }

  handleSave(message) {
    this.setState({
      message: {
        status: null,
        text: null,
      },
    });
    if(this.state.userName.length < 3 || this.state.userName.length > 50){
      this.setState({
        message: {
          status: 'error',
          text: 'Некоректно заполнили поле: Логин. Внимательно заполните все поля! ',
        },
      });
      return;
    }

    if(this.state.userMail.length < 3 || this.state.userMail.length > 50){
      this.setState({
        message: {
          status: 'error',
          text: 'Некоректно заполнили поле: EMail. Внимательно заполните все поля! ',
        },
      });
      return;
    }

    if(this.state.userPass.length < 7 || this.state.userName.userPass > 50){
      this.setState({
        message: {
          status: 'error',
          text: 'Некоректно заполнили поле: Пароль. Внимательно заполните все поля!',
        },
      });
      return;
    }

    fetch(url + '/ajax/isSetUser.php?userName=' + this.state.userName)
    .then(res => res.json())
    .then(data => {

      if(data.status === 'success' && data.isSetUser === 0){
        var postData = new FormData();
        postData.append( "userName", this.state.userName );
        postData.append( "userMail", this.state.userMail );
        postData.append( "userPass", this.state.userPass );
        postData.append( "userRole", this.state.userRole );

        fetch(url + '/ajax/addUser.php', {
          method: 'post',
          body: postData,
        })
        .then(res => res.json())
        .then(data => {

          if(data.status === 'success'){
            this.setState({
              userName: '',
              userMail: '',
              userPass: '',
              userRole: 2,
              message: {
                status: 'success',
                text: 'Пользователь: ' + data.userName + ' (Email: ' + data.userMail + ') успешно создан. Роль: ' + data.userRole,
              },
            });
          } else {
            this.setState({
              message: {
                status: 'error',
                text: 'При добавлении пользователя в базу произошла ошибка',
              },
            });
          }

        }).catch(error => console.error(error))
      } else {
        this.setState({
          message: {
            status: 'error',
            text: 'Пользователь с таким Логином уже существует',
          },
        });
      }

    }).catch(error => console.error(error))
  }

  render(){
    const message = this.state.message;

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="ok" /> Создать Акаунт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Form onSubmit={this.handleSubmit}>
                  <ControlLabel>Логин:</ControlLabel>
                  <FormControl type="text" value={this.state.userName} placeholder="Введите Логин" onChange={this.handleChangeUserName} />
                  <HelpBlock>Минимум 3 символа, максимум 50</HelpBlock>
                  <ControlLabel>Email:</ControlLabel>
                  <FormControl type="text" value={this.state.userMail} placeholder="Введите Email" onChange={this.handleChangeUserMail} />
                  <HelpBlock>Минимум 3 символа, максимум 50 (Нужен для уведомлений)</HelpBlock>
                  <ControlLabel>Пароль:</ControlLabel>
                  <FormControl type="text" value={this.state.userPass} placeholder="Введите Пароль" onChange={this.handleChangeUserPass} />
                  <HelpBlock>Минимум 7 символов, максимум 50</HelpBlock>
                  <ControlLabel>Роль:</ControlLabel>
                  <FormControl componentClass="select" placeholder="select" value={this.state.userRole} onChange={this.handleChangeUserPole}>
                    <option value='2'>Сеошник</option>
                    <option value='1'>Админ</option>
                  </FormControl>
                  <HelpBlock>
                    Сеошник - может только управлять своими сайтам. <br />
                    Админ - полный доступ к всем сайтам и всем функциям приложения.
                  </HelpBlock>
                  {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                  {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                  <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleSave}>Создать</Button>
                </Form>
              </Panel.Body>
            </Panel>);
  }
}

/**************************** EDIT USER ****************************/
class EditUser extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleChangeUserMail = this.handleChangeUserMail.bind(this);
    this.handleChangeUserPass = this.handleChangeUserPass.bind(this);
    this.handleChangeUserPole = this.handleChangeUserPole.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      listUsers: null,
      userId: 0,
      userName: '',
      userMail: '',
      userPass: '',
      userRole: 2,
      message: {
        status: null,
        text: null,
      },
    };
  }

  componentDidMount(){
    fetch(url + '/ajax/getListUsers.php')
      .then(res => res.json())
      .then(data => {
        let newListUsers = [];
        data.forEach(function (row){
          newListUsers[row['id']] = row;
        });

        this.setState({
          listUsers: newListUsers,
          userId: data[0]['id'],
          userName: data[0]['login'],
          userMail: data[0]['mail'],
          userPass: '',
          userRole: data[0]['role'],
        });
      }).catch(error => console.error(error))
  }

  handleSubmit(e){
    e.preventDefault();
  }

  handleChangeSelect(event){
    this.setState({
      userId: event.target.value,
      userName: this.state.listUsers[event.target.value]['login'],
      userMail: this.state.listUsers[event.target.value]['mail'],
      userPass: '',
      userRole: this.state.listUsers[event.target.value]['role'],
    });
  }
  handleChangeUserMail(e) {
    this.setState({ userMail: e.target.value });
  }
  handleChangeUserPass(e) {
    this.setState({ userPass: e.target.value });
  }
  handleChangeUserPole(e) {
    this.setState({ userRole: e.target.value });
  }

  handleSave() {
    this.setState({
      message: {
        status: null,
        text: null,
      },
    });

    if(this.state.userMail.length < 3 || this.state.userMail.length > 50){
      this.setState({
        message: {
          status: 'error',
          text: 'Некоректно заполнили поле: Email. Внимательно заполните все поля!',
        },
      });
      return;
    }

    if(this.state.userPass.length < 7 || this.state.userPass.length > 50){
      this.setState({
        message: {
          status: 'error',
          text: 'Некоректно заполнили поле: Пароль. Внимательно заполните все поля!',
        },
      });
      return;
    }

    if(window.confirm('Вы уверены что хотите внести изменения Акаунта: ' + this.state.userName + ' ?')){

      var postData = new FormData();
      postData.append( "userId", this.state.userId );
      postData.append( "userName", this.state.userName );
      postData.append( "userMail", this.state.userMail );
      postData.append( "userPass", this.state.userPass );
      postData.append( "userRole", this.state.userRole );

      fetch(url + '/ajax/editUser.php', {
        method: 'post',
        body: postData,
      })
      .then(res => res.json())
      .then(data => {

        if(data.status === 'success'){
          this.setState({
            message: {
              status: 'success',
              text: 'Пользователь: ' + data.userName + ' успешно обновлен. Роль: ' + data.userRole + ' Email: ' + data.userMail,
            },
          });
          fetch(url + '/ajax/getListUsers.php')
            .then(res => res.json())
            .then(data => {
              let newListUsers = [];
              data.forEach(function (row){
                newListUsers[row['id']] = row;
              });

              this.setState({
                listUsers: newListUsers,
                userName: newListUsers[this.state.userId]['login'],
                userMail: newListUsers[this.state.userId]['mail'],
                userPass: '',
                userRole: newListUsers[this.state.userId]['role'],
              });
            }).catch(error => console.error(error))
        } else {
          this.setState({
            message: {
              status: 'error',
              text: 'При обновлении пользователя в базе произошла ошибка',
            },
          });
        }

      }).catch(error => console.error(error))
    }
  }

  render(){
    const message = this.state.message;
    const listUsers = this.state.listUsers;
    let listOption = "";

    if (listUsers) {
      listOption = listUsers.map((row) => <option key={row.id} value={row.id}>{row.login}</option>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="pencil" /> Редактировать Акаунт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Form onSubmit={this.handleSubmit}>
                  <ControlLabel>Выберите Акаунт:</ControlLabel>
                  <FormControl componentClass="select" placeholder="select" value={this.state.userId} onChange={this.handleChangeSelect}>
                    {listOption}
                  </FormControl>
                  <br />
                  <ControlLabel>Email:</ControlLabel>
                  <FormControl type="text" value={this.state.userMail} placeholder="Введите Email" onChange={this.handleChangeUserMail} />
                  <HelpBlock>Минимум 3 символов, максимум 50 (нужен для уведомлений)</HelpBlock>

                  <ControlLabel>Пароль:</ControlLabel>
                  <FormControl type="text" value={this.state.userPass} placeholder="Введите Пароль" onChange={this.handleChangeUserPass} />
                  <HelpBlock>Минимум 7 символов, максимум 50</HelpBlock>
                  <ControlLabel>Роль:</ControlLabel>
                  <FormControl componentClass="select" placeholder="select" value={this.state.userRole} onChange={this.handleChangeUserPole}>
                    <option value='2'>Сеошник</option>
                    <option value='1'>Админ</option>
                  </FormControl>
                  <HelpBlock>
                    Сеошник - может только управлять своими сайтам. <br />
                    Админ - полный доступ к всем сайтам и всем функциям приложения.
                  </HelpBlock>
                  {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                  {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                  <Button bsStyle="primary" type="submit" bsSize="small" onClick={this.handleSave}>Сохранить</Button>
                </Form>
              </Panel.Body>
            </Panel>);
  }
}

/**************************** REMOVE USER ****************************/
class RemoveUser extends Component {
  constructor(props) {
    super(props);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.state = {
      listUsers: null,
      userId: 0,
      userName: '',
      message: {
        status: null,
        text: null,
      },
    };
  }

  componentDidMount(){
    fetch(url + '/ajax/getListUsers.php')
      .then(res => res.json())
      .then(data => {
        let newListUsers = [];
        data.forEach(function (row){
          newListUsers[row['id']] = row;
        });

        this.setState({
          listUsers: newListUsers,
          userId: data[0]['id'],
          userName: data[0]['login'],
        });
      }).catch(error => console.error(error))
  }

  handleChangeSelect(event){
    this.setState({
      userId: event.target.value,
      userName: this.state.listUsers[event.target.value]['login'],
    });
  }

  handleRemove() {
    this.setState({
      message: {
        status: null,
        text: null,
      },
    });

    if(window.confirm('Вы уверены что хотите удалить Акаунт: ' + this.state.userName + ' ?')){

      var postData = new FormData();
      postData.append( "userId", this.state.userId );
      postData.append( "userName", this.state.userName );

      fetch(url + '/ajax/removeUser.php', {
        method: 'post',
        body: postData,
      })
      .then(res => res.json())
      .then(data => {

        if(data.status === 'success'){
          this.setState({
            message: {
              status: 'success',
              text: 'Пользователь: ' + data.userName + ' успешно удален.',
            },
          });
          fetch(url + '/ajax/getListUsers.php')
            .then(res => res.json())
            .then(data => {
              let newListUsers = [];
              data.forEach(function (row){
                newListUsers[row['id']] = row;
              });

              this.setState({
                listUsers: newListUsers,
                userId: data[0]['id'],
                userName: data[0]['login'],
              });
            }).catch(error => console.error(error))
        } else {
          this.setState({
            message: {
              status: 'error',
              text: 'При удалении пользователя в базе произошла ошибка',
            },
          });
        }

      }).catch(error => console.error(error))
    }
  }

  render(){
    const message = this.state.message;
    const listUsers = this.state.listUsers;
    let listOption = "";

    if (listUsers) {
      listOption = listUsers.map((row) => <option key={row.id} value={row.id}>{row.login}</option>);
    }

    return (<Panel bsStyle="primary">
              <Panel.Heading>
                <Panel.Title componentClass="h3"><Glyphicon glyph="trash" /> Удалить Акаунт</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <ControlLabel>Выберите Акаунт:</ControlLabel>
                <FormControl componentClass="select" placeholder="select" value={this.state.userId} onChange={this.handleChangeSelect}>
                  {listOption}
                </FormControl>
                <br />
                {message.status === 'success' ? <p className="message bg-success text-success"><Label bsStyle="success">Success</Label> {message.text}</p> : ''}
                {message.status === 'error' ? <p className="message bg-danger text-danger"><Label bsStyle="danger">Error</Label> {message.text}</p> : ''}
                <Button bsStyle="primary" bsSize="small" onClick={this.handleRemove}>Удалить</Button>
              </Panel.Body>
            </Panel>);
  }
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
